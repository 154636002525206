<template>
  <div>
    <div :class="isPc ? 'pc-containers' : 'mb-containers'">
      <div :class="isPc ? 'pc-contents' : 'mb-contents'">
        <v-card class="transaction b-r-16 option m-center" flat>
          <v-row class="p-0 m-0 p-t-36">
            <v-spacer></v-spacer>
            <img
                v-if="errorCode == '-300' || errorCode == '-400'"
                width="120px"
                src="@/assets/icon/Unfortunately.png"
            />
            <img v-else width="64px" src="@/assets/icon/great.png" />
            <v-spacer></v-spacer>
          </v-row>
          <v-card width="640" :class="isPc ? 'm-t-40 b-r-16 m-auto p-20' : 'm-t-40 b-r-16 m-auto p-20 shadow-0'">
            <v-card-text v-if="tool.isNotEmpty(title)" class="text-center bold-f f-s-24 p-t-30">
              {{ title }}
            </v-card-text>
            <v-card-text class="text-center">
              {{ message }}
            </v-card-text>
          </v-card>
          <v-card-text class="text-center bold-f f-s-24 p-t-30 p-20">
            <span v-if="isCountDown">{{ countDown }} </span>
          </v-card-text>
          <v-card-text v-if="this.errorCode === '-400' || this.errorCode === '-300'" class="text-center">
            We are redirecting you to New Image Relief, if not working please click here
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { DataType, Tool } from '@/js/core.js'

const tool = new Tool()
export default {
  props: {
    isPc: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      title: '',
      message: '',
      errorCode: '',
      notice: '',
      countDown: 10,
      isCountDown: false,
      countDownTime: 0,
      icon: '',
      tool: tool
    }
  },
  created () {
    this.errorCode = this.getErrorCode()
    this.$store.commit('setOverlay', false)
    let message = DataType.ERR_CODE[this.errorCode].message

    const parsePersonal = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))
    const personal = tool.isEmpty(parsePersonal) ? {} : parsePersonal

    const parsePortfolio = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))
    const portfolio = tool.isEmpty(parsePortfolio) ? {} : parsePortfolio
    const phone = tool.isNotEmpty(personal.cellPhone) ? personal.cellPhone : personal.homePhone
    message = message
      .replace('CUSTOMER_PHONE', tool.formatPhone(phone))
      .replace('PORTFOLIO_NUMBER', tool.formatPhone(portfolio.telephone))
      .replace('PORTFOLIO_EMAIL', portfolio.email)
      .replace('FIELD_CUSTOMER_NAME', personal.firstName)
    this.message = message

    this.title = DataType.ERR_CODE[this.errorCode].title
    this.icon = '@/assets/icon/default.png'
    if (this.errorCode === '-400' || this.errorCode === '-300') {
      this.isCountDown = true
      this.countDownTime = setInterval(() => {
        this.countDown--
        if (this.countDown === 0) {
          this.isCountDown = false
          clearInterval(this.countDownTime)
        }
      }, 1000)
    }
  },
  methods: {
    getErrorCode () {
      const code = this.$route.params.errorCode
      if (parseInt(code) < 0) {
        this.notice = 'mdi mdi-emoticon-sad-outline'
      } else if (parseInt(code) > 0 && parseInt(code) < 200) {
        this.notice = 'mdi mdi-emoticon-neutral-outline'
      } else {
        this.notice = 'mdi mdi-emoticon-outline'
      }
      return code
    }
  },
  mounted () {
    // 禁用浏览器后退操作
    history.pushState(null, null, document.URL)
    window.addEventListener('popstate', function () {
      history.pushState(null, null, document.URL)
    })
  }
}
</script>
<style>
.shadow-0 {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)!important;
}
</style>
